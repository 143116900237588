import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { SentryTest } from '../components/Elements'
import { VideoBG } from '../components/Parts'
import { Contact, Portfolio, Technologies } from '../components/Sections'

const IndexPage = ({ data }) => {
  const { contactNode } = data.datoCmsMainpage
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <SentryTest>
        <VideoBG props={ data.datoCmsMainpage } />
      </SentryTest>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1rem 1rem`,
          paddingTop: 0,
        }}
      >
        <SentryTest>
          <Portfolio />
        </SentryTest>
        <SentryTest>
          <Technologies />
        </SentryTest>
        <SentryTest>
          <Contact text={ contactNode }/>
        </SentryTest>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MainPage {
    datoCmsMainpage {
      heading
      subheading
      subheadingNode {
        childMarkdownRemark {
          html
        }
      }
      videoBg {
        url
      }
      videoBgPlaceholder {
        fluid(maxWidth: 500, maxHeight: 200) {
          ...GatsbyDatoCmsFluid
        }
      }
      contactNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;